<template>
    <ModalBox
        :is-active.sync="isModalActive"
        :is-loading="isLoading"
        @closed="onClose">
        <template #header>
            <h5 class="title is-5">
                {{ $t("admin.efficiency.plans.modal.history.header.title") }}
            </h5>
        </template>
        <div>
            <Timeline
                :items="timeline"
                :count="count"
                :load-data="loadPlans"
                :with-edit-control="false"
                @timeline-delete-item="removePlan">
            </Timeline>
        </div>
    </ModalBox>
</template>

<script>
  import ModalBox from "@/components/Common/ModalBox";
  import Timeline from "@/components/Common/Timeline/Timeline";
  import { moment } from "@core/filters";
  import { GET_PLANS, GET_PLANS_HISTORY, REMOVE_PLAN } from "@core/store/action-constants";
  import { SET_EMPTY, SET_EMPTY_DATA, SET_MODAL_ACTIVE, UPDATE_PAGINATION } from "@core/store/mutation-constants";
  import { mapActions, mapMutations, mapState } from "vuex";
  
  export default {
    name: "PlansHistoryModal",
    
    components: {
      ModalBox,
      Timeline
    },
    
    data () {
      return {
        updateOnClose: false
      };
    },

    computed: {
      ...mapState("admin/efficiencyPlansHistory", {
        history: ({ data }) => data,
        count: ({ data }) => data?.count || 0,
        items: ({ data }) => data?.items || [],
        currentPlanDate: ({ manager: { currentEfficiency } }) => currentEfficiency?.applyDate ? new Date(currentEfficiency.applyDate) : null
      }),

      isModalActive: {
        get () {
          return this.$store.state.admin.efficiencyPlansHistory.isModalActive;
        },

        set (value) {
          this.SET_MODAL_ACTIVE(value);
        }
      },

      timeline () {
        return this.history?.items.map(({ id, value, applyDate }) => {
          let badgeType;
          const _applyDate = new Date(applyDate);

          if (_applyDate < this.currentPlanDate) {
            // Прошлый план
            badgeType = "light";
          } else if (_applyDate > this.currentPlanDate) {
            // Будущий план
            badgeType = "success-light";
          } else {
            // Текущий план
            badgeType = "success";
          }

          return {
            id,
            title: value,
            content: moment(applyDate),
            // TODO: Высчитывать на основе текущий, будущий или прошедший это план
            badgeType
          };
        });
      },

      isLoading () {
        return this.$wait(`admin/efficiencyPlansHistory${ REMOVE_PLAN }`);
      }
    },

    methods: {
      ...mapActions("admin/efficiencyPlans", [
        GET_PLANS_HISTORY,
        REMOVE_PLAN,
        GET_PLANS
      ]),

      ...mapActions("admin/efficiencyPlansHistory", [
        GET_PLANS_HISTORY,
        REMOVE_PLAN
      ]),

      ...mapMutations("admin/efficiencyPlansHistory", [
        SET_MODAL_ACTIVE,
        SET_EMPTY,
        SET_EMPTY_DATA,
        UPDATE_PAGINATION
      ]),

      async loadPlans () {
        this.UPDATE_PAGINATION();
        await this.GET_PLANS_HISTORY();
      },

      removePlan (id) {
        this.$buefy.dialog.confirm({
          title: this.$t("admin.efficiency.plans.modal.edit.confirm.removePlan.title"),
          message: this.$t("admin.efficiency.plans.modal.edit.confirm.removePlan.message"),
          confirmText: this.$t("admin.efficiency.plans.modal.edit.confirm.removePlan.confirm"),
          cancelText: this.$t("admin.efficiency.plans.modal.edit.confirm.removePlan.cancel"),
          type: "is-danger is-light",
          onConfirm: async () => {
            await this.REMOVE_PLAN(id);
            this.UPDATE_PAGINATION({
              page: 1,
              perPage: this.items.length
            });
            this.SET_EMPTY_DATA();
            this.GET_PLANS_HISTORY();
            this.updateOnClose = true;
          }
        });
      },

      onClose () {
        if (this.updateOnClose) {
          this.GET_PLANS();
        }
      }
    },

    watch: {
      isModalActive (active) {
        if (active) {
          this.GET_PLANS_HISTORY();
        } else {
          this.SET_EMPTY();
        }
      }
    }
  };
</script>

<style>
</style>
