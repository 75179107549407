<template>
    <div v-if="items && items.length === 0">
        <slot name="empty">
            <div class="hero is-medium has-text-centered">
                <div class="hero-body">
                    <h2 class="is-size-4 is-bold has-text-weight-medium has-text-grey">
                        <slot name="empty-placeholder">
                            {{ $t(`common.timeline.empty`) }}
                        </slot>
                    </h2>
                </div>
            </div>
        </slot>
    </div>
    <div
        v-else-if="items"
        class="timeline">
        <ul class="timeline-list">
            <li
                :key="i"
                v-for="(item, i) in items"
                class="timeline-list-item">
                <TimelineItem
                    :item="item"
                    :with-edit-control="withEditControl"
                    :with-remove-control="withRemoveControl"
                    @timeline-delete-item="onDelete"
                    @timeline-edit-item="onEdit">
                </TimelineItem>
            </li>
        </ul>
        <InfiniteLoading
            v-if="isInfinityLoading"
            @infinite="infiniteHandler">
        </InfiniteLoading>
    </div>
    <div v-else>
        <slot name="loader">
            <div class="loader-wrapper">
                <b-loading
                    :active="true"
                    :is-full-page="false"></b-loading>
            </div>
        </slot>
    </div>
</template>

<script>
  import InfiniteLoading from "vue-infinite-loading";
  import TimelineItem from "@/components/Common/Timeline/TimelineItem";
  
  export default {
    components: {
      TimelineItem,
      InfiniteLoading
    },

    props: {
      items: {
        type: Array,
        default: null
      },

      count: {
        type: Number,
        default: 0
      },

      loadData: {
        type: Function,
        default: null
      },

      withEditControl: {
        type: Boolean,
        default: true
      },

      withRemoveControl: {
        type: Boolean,
        default: true
      }
    },

    computed: {
      isInfinityLoading () {
        const { loadData, items, count } = this;
        return !!loadData && items < count;
      }
    },

    methods: {
      onDelete (id) {
        this.$emit("timeline-delete-item", id);
      },

      onEdit (id) {
        this.$emit("timeline-edit-item", id);
      },

      async infiniteHandler ($state) {
        if (this.count > this.items.length) {
          await this.loadData();
          $state.loaded();
        } else {
          $state.complete();
        }
      }
    }
  };
</script>

<style lang="scss">
.timeline {
    $timeline-badge-size: 30px;

    &-list-item {
        position: relative;

        & ~ &:before,
        &:not(:last-child):after {
            content: "";
            position: absolute;
            width: 3px;
            background-color: #e0e0e0;
            transform: translateX(-50%);
            left: $timeline-badge-size / 2;
        }

        & ~ &:before {
            height: 0.75em;
        }

        &:not(:last-child):after {
            top: calc(0.75em + #{ $timeline-badge-size });
            height: calc(100% - #{ $timeline-badge-size });
        }
    }
}
</style>
