<template>
    <TablePlaceholder
        v-slot="{ items, count }"
        :data="plans">
        <b-table
            :data="items"
            :loading="isLoading.getPlans"
            :paginated="isPaginated"
            :per-page="perPage"
            :current-page="page"
            :total="count"
            backend-pagination
            class="common-table with-options"
            @page-change="updateCurrentPage">
            <b-table-column
                v-slot="{ row }"
                :label="$t('admin.efficiency.plans.table.labels.manager')">
                <UserOutput :user="row"></UserOutput>
            </b-table-column>
    
            <b-table-column
                v-slot="{ row }"
                :label="$t('admin.efficiency.plans.table.labels.regDate')">
                {{ formatEmptyString(moment(row.activity.registeredAt)) }}
            </b-table-column>
    
            <b-table-column
                v-slot="{ row }"
                :label="$t('admin.efficiency.plans.table.labels.activeWebmastersAmount')">
                {{ formatEmptyString(row.activeWebmastersAmount) }}
            </b-table-column>
    
            <b-table-column
                v-slot="{ row }"
                :width="200"
                :label="$t('admin.efficiency.plans.table.labels.comment')">
                <div class="is-flex is-align-items-center">
                    <ShowMore
                        :data="row.comment"
                        :max-string="50"></ShowMore>
                    <DropdownComment
                        :ref="`dropdown-${ row.id }`"
                        :is-loading="isLoading.updateComment"
                        :value="row.comment"
                        @update="changeComment($event, row.id)">
                    </DropdownComment>
                </div>
            </b-table-column>
    
            <b-table-column
                v-slot="{ row }"
                :label="$t('admin.efficiency.plans.table.labels.currentEfficiency')">
                <ColoredStatus
                    v-if="existFutureEfficiency(row)"
                    type="is-success"
                    :tooltip="$t('admin.efficiency.plans.table.labels.existFutureEfficiency')"
                    tooltip-position="is-top"
                    tooltip-type="is-success">
                </ColoredStatus>
                {{ efficiencyFormatter(row.currentEfficiency) }}
            </b-table-column>
    
            <b-table-column v-slot="{ row }">
                <DropdownOptions rounded>
                    <DropdownOption
                        icon="plus"
                        type="is-light is-success"
                        @click="showEditModal(row.id)">
                        {{ $t('admin.efficiency.plans.table.labels.addPlan') }}
                    </DropdownOption>
    
                    <DropdownOption
                        icon="history"
                        type="is-light"
                        @click="showHistory(row)">
                        {{ $t('admin.efficiency.plans.table.labels.history') }}
                    </DropdownOption>
                </DropdownOptions>
            </b-table-column>
        </b-table>
    </TablePlaceholder>
</template>

<script>
  import ColoredStatus from "@/components/Common/ColoredStatus";
  import DropdownComment from "@/components/Common/DropdownComment";
  import DropdownOption from "@/components/Common/DropdownOptions/DropdownOption";
  import DropdownOptions from "@/components/Common/DropdownOptions/DropdownOptions";
  import ShowMore from "@/components/Common/ShowMore";
  import TablePlaceholder from "@/components/Common/Table/TablePlaceholder";
  import UserOutput from "@/components/Common/UserOutput";
  import { formatEmptyString, moment, toFixed } from "@core/filters";
  import { EDIT_PLANS_ADMIN_COMMENT, GET_PLANS } from "@core/store/action-constants";
  import {
    EMPTY_PLANS_EDITABLE_COMMENT,
    SET_MODAL_ACTIVE,
    SET_PLANS_EDIT_MANAGER_ID,
    SET_PLANS_HISTORY_MANAGER,
    UPDATE_PLANS_EDITABLE_COMMENT,
    UPDATE_PLANS_PAGINATION
  } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { mapActions, mapMutations, mapState } from "vuex";
  
  export default {
    name: "PlansTable",
    
    components: {
      TablePlaceholder,
      UserOutput,
      ColoredStatus,
      DropdownOptions,
      DropdownOption,
      ShowMore,
      DropdownComment
    },
    
    created () {
      const {
        page,
        perPage
      } = this.$route.query;
      const pagination = {
        page: Number(page) || 1,
        perPage: Number(perPage) || 25
      };
  
      this.UPDATE_PLANS_PAGINATION(pagination);
      this.GET_PLANS();
    },
    
    computed: {
      ...mapState("admin/efficiencyPlans", [
        "plans"
      ]),
      
      ...mapFields("admin/efficiencyPlans", {
        fields: ["perPage", "page"],
        base: "pagination",
        action: UPDATE_PLANS_PAGINATION
      }),
  
      isLoading () {
        return {
          getPlans: this.$wait(`admin/efficiencyPlans/${ GET_PLANS }`),
          updateComment: this.$wait(`admin/efficiencyPlans/${ EDIT_PLANS_ADMIN_COMMENT }`)
        };
      },
  
      isPaginated () {
        return this.plans.count > this.perPage;
      }
    },
  
    methods: {
      ...mapActions("admin/efficiencyPlans", [
        GET_PLANS,
        EDIT_PLANS_ADMIN_COMMENT
      ]),
    
      ...mapMutations("admin/efficiencyPlans", [
        UPDATE_PLANS_PAGINATION,
        UPDATE_PLANS_EDITABLE_COMMENT,
        EMPTY_PLANS_EDITABLE_COMMENT
      ]),
    
      ...mapMutations("admin/efficiencyPlansHistory", {
        SET_PLANS_HISTORY_MANAGER,
        setIsHistoryModal: SET_MODAL_ACTIVE
      }),
    
      ...mapMutations("admin/efficiencyPlansEdit", {
        SET_PLANS_EDIT_MANAGER_ID,
        setIsEditModal: SET_MODAL_ACTIVE
      }),
    
      async changeComment (comment, adminId) {
        try {
          this.UPDATE_PLANS_EDITABLE_COMMENT({ adminId, comment });
          await this.EDIT_PLANS_ADMIN_COMMENT();
          this.GET_PLANS();
          this.$refs[`dropdown-${ adminId }`].toggle();
          this.$buefy.toast.open({
            message: this.$t("admin.efficiency.plans.messages.changeCommentSuccess"),
            type: "is-success"
          });
        } catch (_) {
          this.$buefy.toast.open({
            message: this.$t("admin.efficiency.plans.messages.changeCommentError"),
            type: "is-warning"
          });
        }
      },

      updateCurrentPage (page) {
        this.page = page;
        this.GET_PLANS();
      },
    
      efficiencyFormatter (efficiency) {
        return efficiency ? toFixed(efficiency.value, 2) : this.formatEmptyString(efficiency);
      },
    
      showHistory (manager) {
        this.SET_PLANS_HISTORY_MANAGER(manager);
        this.setIsHistoryModal(true);
      },
    
      existFutureEfficiency (row) {
        return !!row.futureEfficiency;
      },
    
      showEditModal (managerId) {
        this.SET_PLANS_EDIT_MANAGER_ID(managerId);
        this.setIsEditModal(true);
      },
    
      formatEmptyString,
      moment
    }
  };
</script>

<style lang="scss" scoped>
    ::v-deep {
        .loading-overlay {
            border-radius: 10px
        }
    ;
    }

</style>
