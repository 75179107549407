<template>
    <div class="wrapper">
        <PlansTable></PlansTable>
        <PlansHistoryModal></PlansHistoryModal>
        <PlansEditModal></PlansEditModal>
    </div>
</template>

<script>
  import { SET_EMPTY } from "@core/store/mutation-constants";
  import { mapMutations } from "vuex";
  import PlansTable from "@/components/Admin/Efficiency/EfficiencyPlans/PlansTable";
  import PlansHistoryModal from "@/components/Admin/Efficiency/EfficiencyPlans/PlansHistoryModal";
  import PlansEditModal from "@/components/Admin/Efficiency/EfficiencyPlans/PlansEditModal";

  export default {
    name: "EfficiencyPlans",
    
    components: {
      PlansTable,
      PlansHistoryModal,
      PlansEditModal
    },

    methods: {
      ...mapMutations("admin/efficiencyPlans", [SET_EMPTY])
    },

    beforeDestroy () {
      this.SET_EMPTY();
    }
  };
</script>

<style scoped></style>
