<template functional>
    <span v-if="props.user">
        {{ $options.methods.formatEmptyString(props.user.login) }}
        [{{ $options.methods.formatEmptyString(props.user.intId) }}]
    </span>
    <span v-else>
        {{ $options.methods.formatEmptyString(null) }}
    </span>
</template>

<script>
  import { formatEmptyString } from "@core/filters";
  
  export default {
    name: "UserOutput",
    props: {
      user: {
        type: Object,
        default: null
      }
    },
    methods: {
      formatEmptyString
    }
  };
</script>

<style scoped>

</style>
