<template>
    <div class="timeline-item">
        <div
            :class="{
                'mt-3': true,
                'timeline-badge': true,
                [`has-background-${ item.badgeType }`]: true
            }">
        </div>
        <div class="timeline-panel">
            <TileItem
                :item="item">
                <template #buttons>
                    <LTooltip
                        v-if="withEditControl"
                        :label="$t(`common.entity.tileItem.tooltip.edit`)"
                        position="is-bottom"
                        type="is-warning">
                        <b-button
                            icon-left="pen"
                            size="is-small"
                            type="is-warning is-light"
                            @click.prevent.stop="onEdit">
                        </b-button>
                    </LTooltip>

                    <LTooltip
                        v-if="withRemoveControl"
                        :label="$t(`common.entity.tileItem.tooltip.delete`)"
                        position="is-bottom"
                        type="is-danger">
                        <b-button
                            icon-left="trash-alt"
                            icon-pack="far"
                            size="is-small"
                            type="is-danger is-light"
                            @click.prevent.stop="onDelete">
                        </b-button>
                    </LTooltip>
                </template>
            </TileItem>
        </div>
    </div>
</template>

<script>
  import TileItem from "@/components/Common/TileItem";
  
  export default {
    components: {
      TileItem
    },

    props: {
      item: {
        type: Object,
        required: true
      },

      withEditControl: {
        type: Boolean,
        default: true
      },

      withRemoveControl: {
        type: Boolean,
        default: true
      }
    },

    methods: {
      onDelete () {
        this.$emit("timeline-delete-item", this.item.id);
      },

      onEdit () {
        this.$emit("timeline-edit-item", this.item.id);
      }
    }
  };
</script>

<style lang="scss">
.timeline {
    $timeline-badge-size: 30px;

    &-item {
        display: flex;
    }

    &-badge {
        flex-shrink: 0;
        box-sizing: border-box;
        border-radius: 50%;
        width: $timeline-badge-size;
        height: $timeline-badge-size;
        z-index: 1;
    }

    &-panel {
      flex-grow: 1;
    }
}
</style>
