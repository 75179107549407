<template>
    <div class="show-more">
        <div>
            <slot
                v-if="!asModal && !activeModal || asModal"
                :truncatedValue="truncatedValue"
                :value="value"
                name="truncateContent">
                <template v-if="Array.isArray(truncatedValue) && truncatedValue.length > 0">
                    <ul>
                        <li
                            :key="i"
                            v-for="(item, i) in truncatedValue">
                            {{ item }}
                        </li>
                    </ul>
                </template>
                <template v-else>
                    {{ formatEmptyString(truncatedValue) }}
                </template>
            </slot>
            <slot
                v-if="!asModal && activeModal"
                :value="value"
                name="fullContent">
                <template v-if="Array.isArray(value)">
                    <ul>
                        <li
                            :key="i"
                            v-for="(item, i) in value">
                            {{ item }}
                        </li>
                    </ul>
                </template>
                <template v-else>
                    {{ formatEmptyString(value) }}
                </template>
            </slot>
        </div>
        <slot>
            <template v-if="value && isHide">
                <slot>
                    <template v-if="asModal">
                        <el-button
                            class="show-more-link"
                            type="text"
                            @click.prevent.stop="activeModal = true">
                            {{ $t("common.table.views.showMore.more") }}
                        </el-button>
                    </template>

                    <template v-else-if="!asModal && !activeModal">
                        <el-button
                            class="show-more-link"
                            type="text"
                            @click.prevent.stop="activeModal = true">
                            {{ $t("common.table.views.showMore.more") }}
                        </el-button>
                    </template>

                    <template v-else-if="!asModal && activeModal">
                        <el-button
                            class="show-more-link"
                            type="text"
                            @click.prevent.stop="activeModal = false">
                            {{ $t("common.table.views.showMore.less") }}
                        </el-button>
                    </template>
                </slot>
            </template>
        </slot>

        <el-dialog
            v-if="asModal"
            :visible.sync="activeModal">
            <template>
                <slot
                    :value="value"
                    name="fullContent">
                    <template v-if="Array.isArray(value)">
                        <ul>
                            <li
                                :key="i"
                                v-for="(item, i) in value">
                                {{ item }}
                            </li>
                        </ul>
                    </template>
                    <template v-else>
                        {{ value }}
                    </template>
                </slot>
            </template>
            <template #footer>
                <div class="container">
                    <div class="columns">
                        <div class="column">
                            <el-button
                                class="is-fullwidth is-light-button"
                                @click="activeModal = false">
                                {{ $t("common.table.views.showMore.close") }}
                            </el-button>
                        </div>
                    </div>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
  import { formatEmptyString } from "@core/filters";
    
  export default {
    name: "ShowMore",
    props: {
      data: {
        type: [Array, String],
        default: () => []
      },
            
      maxString: {
        type: Number,
        default: 60
      },
            
      maxArray: {
        type: Number,
        default: 1
      },
            
      asModal: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        activeModal: false
      };
    },
    computed: {
      value () {
        if (typeof this.data === "object") {
          if (Array.isArray(this.data)) {
            return this.data;
          }
        }
        return this.data;
      },
            
      isHide () {
        return Array.isArray(this.value) && this.value.length > 1 ||
          typeof this.value === "string" && this.value.length > this.maxString;
      },
            
      truncatedValue () {
        if (Array.isArray(this.value)) {
          return this.value.slice(0, this.maxArray);
        } else {
          return this.value && this.value.length > this.maxString ?
            this.value.substr(0, this.maxString) : this.value;
        }
      }
    },
        
    methods: {
      formatEmptyString
    }
  };
</script>

<style lang="scss" scoped>
    .show-more {
        word-break: break-word;

        ::v-deep {
            .el-dialog {
                margin: 15vh auto;

                &__header {
                    padding: 20px 20px 10px;
                }

                &__body {
                    padding: 30px 20px;
                }

                &__footer {
                    padding: 10px 20px 20px;
                }

                .el-button {
                    padding: 12px 20px;
                }
            }
        }

        &-link {
            cursor: pointer;
        }
    }
</style>