<template>
    <ModalBox
        :is-active.sync="isModalActive"
        :is-loading="isLoading"
        @submit="onSubmit">
        <template #header>
            <h5 class="title is-5">
                {{ $t("admin.efficiency.plans.modal.edit.header.addTitle") }}
            </h5>
        </template>
    
        <div class="columns">
            <div class="column">
                <b-field>
                    <!-- TODO: без horizontal-time-picker дейтпикер не влазит в full-hd экран, скролла нет -->
                    <b-datetimepicker
                        v-model="localApplyDate"
                        :placeholder="$t('admin.efficiency.plans.modal.edit.body.applyDate')"
                        icon="calendar-alt"
                        horizontal-time-picker
                        append-to-body>
                    </b-datetimepicker>
                </b-field>
            </div>
            <div class="column is-5">
                <b-field>
                    <b-numberinput
                        v-model="plan"
                        :placeholder="$t('admin.efficiency.plans.modal.edit.body.planValue')"
                        :step="0.01"
                        :min="0"
                        controls-position="compact">
                    </b-numberinput>
                </b-field>
            </div>
        </div>

        <template #footer>
            <div class="container">
                <div class="columns">
                    <div class="column">
                        <b-button
                            class="is-fullwidth"
                            native-type="submit"
                            type="is-success is-light">
                            {{ $t("common.buttons.save") }}
                        </b-button>
                    </div>
                    <div class="column">
                        <b-button
                            class="is-fullwidth"
                            type="is-light-button"
                            @click="SET_EMPTY">
                            {{ $t("common.buttons.cancel") }}
                        </b-button>
                    </div>
                </div>
            </div>
        </template>
    </ModalBox>
</template>

<script>
  import ModalBox from "@/components/Common/ModalBox";
  import { moment, momentInst } from "@core/filters";
  import { ADD_PLAN, GET_PLANS } from "@core/store/action-constants";
  import { SET_EMPTY, SET_MODAL_ACTIVE, UPDATE_PLANS_EDIT_FORM } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { mapActions, mapMutations, mapState } from "vuex";
  
  export default {
    name: "PlansEditModal",
    
    components: {
      ModalBox
    },
    
    data () {
      return {
        localApplyDate: null
      };
    },

    computed: {
      ...mapFields("admin/efficiencyPlansEdit", {
        fields: ["plan", "applyDate"],
        base: "form",
        action: UPDATE_PLANS_EDIT_FORM
      }),

      ...mapState("admin/efficiencyPlansEdit", {
        isEdit: ({ planId }) => !!planId
      }),

      isModalActive: {
        get () {
          return this.$store.state.admin.efficiencyPlansEdit.isModalActive;
        },

        set (value) {
          this.SET_MODAL_ACTIVE(value);
        }
      },

      isLoading () {
        return this.$wait(`admin/efficiencyPlansEdit/${ ADD_PLAN }`);
      }
    },

    methods: {
      ...mapMutations("admin/efficiencyPlansEdit", [
        SET_EMPTY,
        SET_MODAL_ACTIVE
      ]),

      ...mapActions("admin/efficiencyPlansEdit", [
        ADD_PLAN
      ]),

      ...mapActions("admin/efficiencyPlans", [
        GET_PLANS
      ]),

      onSubmit () {
        if (this.isEdit) {
          // Редактирование плана
        } else {
          this.addPlan();
        }
      },

      async addPlan () {
        try {
          await this.ADD_PLAN();
          this.SET_EMPTY();
          this.$buefy.toast.open({
            message: this.$t("admin.efficiency.plans.messages.addPlanSuccess"),
            type: "is-success"
          });
          this.GET_PLANS();
        } catch ({ errors }) {
          const errorsMessages = errors?.map(error => error.message);

          if (errorsMessages.includes("THE_DATE_IS_ALREADY_TAKEN")) {
            this.$buefy.toast.open({
              message: this.$t("admin.efficiency.plans.messages.addPlanDateError"),
              type: "is-danger"
            });
          } else {
            this.$buefy.toast.open({
              message: this.$t("admin.efficiency.plans.messages.addPlanError"),
              type: "is-danger"
            });
          }
        }
      }
    },

    watch: {
      isModalActive (active) {
        if (active) {
          this.localApplyDate = momentInst()
            .startOf("day")
            .add(1, "days")
            .toDate();
        } else {
          this.SET_EMPTY();
        }
      },

      localApplyDate (date) {
        this.applyDate = moment(date, "YYYY-MM-DDTHH:mm:ss", true);
      }
    }
  };
</script>

<style>
</style>
